import React from "react";

import Frame from "~/interface/layout/frame";
import SEO from "~/helpers/seo";
import Navigation from "~/interface/compositions/navigation";
import Footer from "~/interface/compositions/footer";
import Map from "~/interface/compositions/map";

const AboutUsPage = () => {
  return (
    <Frame className="bg-black text-white">
      <div className="lg:h-[calc(100vh-170px)] md:flex justify-between">
        <div className="flex flex-col md:flex-row gap-10 sm:gap-28 h-full px-10 sm:px-20 md:px-[128px]">
          <Navigation />

          <div className="flex items-center tracking-[0.02em]">
            <div className="flex flex-col lg:flex-row gap-20 py-10">
              <div className="max-w-[360px]">
                <h1 className="mb-6 text-xl font-semibold text-white">
                  企業情報
                </h1>

                <ul className="mb-20">
                  <li className="mb-6">
                    企業名: 株式会社Artefact (英: Artefact Inc.)
                  </li>
                  <li className="mb-6">本社所在地: 東京都目黒区駒場 4-6-2</li>
                  <li className="mb-6">代表者: 菅沼 祥太郎</li>
                </ul>

                <h2 className="mb-6 text-xl font-semibold text-white">
                  事業内容
                </h2>

                <ul className="list-decimal list-outside pl-5">
                  <li className="mb-6">オンラインメディア運営</li>
                  <li>デジタルプロダクト戦略に関するコンサルティング</li>
                </ul>
              </div>

              <div className="max-w-[360px]">
                <h2 className="mb-6 text-xl font-semibold text-white">
                  企業沿革
                </h2>

                <ul>
                  <li className="mb-6">
                    2015年9月: 東京都世田谷区にて The Shell 株式会社を設立
                  </li>
                  <li className="mb-6">
                    2018年2月: プロジェクトマネージメントツール
                    <a href="https://www.producthunt.com/posts/crumby">
                      「Crumby」
                    </a>
                    をリリース
                  </li>
                  <li className="mb-6">
                    2018年3月: 株式会社
                    Artefactに社名変更、本社を東京都目黒区に移転
                  </li>
                  <li className="mb-6">
                    2019年3月: オンライン決済ゲートウェイ
                    <a href="https://github.com/finch-tech/finch">「Finch」</a>
                    をリリース
                  </li>
                  <li className="mb-6">
                    2020年2月: 時間割管理アプリ
                    <a href="https://schooly.rocks/">「Schooly」</a>をリリース
                  </li>
                  <li>
                    2023年1月: Webメディア
                    <a href="https://www.unprinted.design">「unprinted」</a>
                    をメディア事業として運営開始
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden lg:block bg-gray-darkest h-full w-1/5 relative border-l-[0.5px] border-white">
          <Map />
        </div>
      </div>

      <Footer />
    </Frame>
  );
};

export default AboutUsPage;

export const Head = () => {
  return (
    <SEO
      title="Artefactについて | 株式会社Artefact"
      description=""
      url="/about_us/"
    />
  );
};
